var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { color, margin, size } from 'styled-system';
import { useOpenDisclaimer } from '../../hooks/drawer';
import { boxSizing, textDecoration } from '../../theme/system-utilities';
import { Flex } from '../Flex';
import InfoButton from '../InfoButton';
import { LinkRegularLR3 } from '../Typography';
var DisclaimerContainer = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), boxSizing);
DisclaimerContainer.defaultProps = {
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    pt: 'sp_8',
};
var Info = styled(InfoButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", "\n"], ["\n  ", ";\n  ", "\n"])), size, margin);
var DisclaimerLink = styled(LinkRegularLR3)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  white-space: nowrap;\n  cursor: pointer;\n"], ["\n  ", ";\n  ", ";\n  white-space: nowrap;\n  cursor: pointer;\n"])), color, textDecoration);
var disclaimerLinkStyles = {
    textDecoration: 'underline',
};
var LegalDisclaimer = function (_a) {
    var disclaimerColor = _a.color, content = _a.content, label = _a.label, withIcon = _a.withIcon, iconSize = _a.iconSize, buttonSize = _a.buttonSize, withNoMargin = _a.withNoMargin, props = __rest(_a, ["color", "content", "label", "withIcon", "iconSize", "buttonSize", "withNoMargin"]);
    var onClick = useOpenDisclaimer({ html: content }, { preventDefault: true });
    return (React.createElement(DisclaimerContainer, __assign({}, props), withIcon ? (React.createElement(Info, __assign({ onClick: onClick, size: buttonSize, iconProps: __assign(__assign({}, (iconSize && { size: iconSize })), (disclaimerColor && { fill: disclaimerColor })) }, (withNoMargin && { m: 'sp_0' })))) : (React.createElement(DisclaimerLink, __assign({ "data-testid": "disclaimer-link", color: disclaimerColor, onClick: onClick }, disclaimerLinkStyles), label))));
};
export default LegalDisclaimer;
var templateObject_1, templateObject_2, templateObject_3;
